import React from "react"

const IdeasSplash: React.FC = () => {
  return (
    <div>
      In Progress...
    </div>
  )
}

export default IdeasSplash
